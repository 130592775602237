exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-infrastructure-mdx": () => import("./../../../src/pages/infrastructure.mdx" /* webpackChunkName: "component---src-pages-infrastructure-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-posts-spring-repository-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/rifatdover/Projects/Eldentech/Website/eldentech/posts/spring-repository.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-posts-spring-repository-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-posts-test-2-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/rifatdover/Projects/Eldentech/Website/eldentech/posts/test2.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-posts-test-2-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-src-pages-infrastructure-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/rifatdover/Projects/Eldentech/Website/eldentech/src/pages/infrastructure.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-users-rifatdover-projects-eldentech-website-eldentech-src-pages-infrastructure-mdx" */),
  "component---src-pages-security-mdx": () => import("./../../../src/pages/security.mdx" /* webpackChunkName: "component---src-pages-security-mdx" */),
  "component---src-pages-software-development-mdx": () => import("./../../../src/pages/software-development.mdx" /* webpackChunkName: "component---src-pages-software-development-mdx" */),
  "component---src-pages-testing-mdx": () => import("./../../../src/pages/testing.mdx" /* webpackChunkName: "component---src-pages-testing-mdx" */)
}

